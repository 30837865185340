<template>
    <div>
        <div
            id="contador"
            :style="{
                'background-color': popup.has_countdown ? counterBackgroundColor : '',
                width: popup.has_countdown ? '' : '440px',
            }"
            v-if="!isPopupHidden && !isMobile"
            :class="[
                popup ? 'countdown-popup' : '',
                popup && !popup.has_countdown ? 'not_countdown' : '',
                popup.image_desktop ? 'count-popup-img' : '',
            ]"
        >
            <img
                v-if="popup.image_desktop"
                :src="`https://chico-rei.imgix.net/${popup.image_desktop}?auto=compress,format&q=65&fit=crop`"
                style="
                    position: fixed;
                    bottom: 140px;
                    left: 17px;

                    z-index: 1000;
                "
            />

            <div
                id="contador-content"
                :class="(isMobile || popup ? 'mobile' : 'desktop') + (viewButton ? '-v2' : '-v1')"
                :style="{
                    height: popup.has_countdown ? '' : '120px',
                }"
            >
                <div
                    :id="isMobile || popup ? 'contador-mobile-wrapper-primary' : ''"
                    :style="{
                        'background-color': popup.background_color,
                    }"
                >
                    <div :style="contadorContentStyle">
                        <span
                            id="contador-title"
                            :style="{
                                color: popup.color,
                            }"
                            v-html="popup.popup_phrase"
                        ></span>
                    </div>
                    <a
                        class="cursor-pointer"
                        style="
                            position: absolute;
                            color: #797978;
                            top: 0.25rem;
                            right: 0.5rem;
                            font-weight: 700;
                        "
                        @click="closeCountdown()"
                        v-if="
                            (popup && popup.has_countdown) ||
                            (!popup.has_countdown && !popup.discount)
                        "
                        >X</a
                    >
                    <div
                        id="contador-coupon"
                        v-if="(isMobile || popup) && popup.discount && popup.has_countdown"
                    >
                        <span id="contador-coupon-text" :style="{ color: popup.color }"
                            >Use o cupom:</span
                        >
                        <div id="coupon-wrapper">
                            <span
                                id="coupon-wrapper-text"
                                :class="{ 'coupon-smaller': popup.discount.name.length > 10 }"
                                >{{ this.popup.discount.name }}</span
                            >
                        </div>
                    </div>
                </div>

                <div
                    :id="isMobile || popup ? 'contador-mobile-wrapper-secondary' : ''"
                    :style="{
                        'background-color': counterBackgroundColor,
                    }"
                    :class="[popup.image_desktop ? 'count-popup-full' : '']"
                    v-if="popup.has_countdown"
                >
                    <a
                        v-if="popup.has_countdown"
                        class="cursor-pointer"
                        style="
                            position: absolute;
                            color: #797978;
                            top: 0.25rem;
                            right: 0.5rem;
                            font-weight: 700;
                        "
                        @click="closeCountdown()"
                        >X</a
                    >
                    <div id="contador-timer">
                        <span
                            id="contador-timer-text"
                            :style="{
                                color: '#fff',
                            }"
                            v-if="popup.has_countdown"
                            >Acaba em:</span
                        >

                        <Counter
                            v-if="popup.has_countdown"
                            :deadline="popup.date_to"
                            :counterTextColor="'#fff'"
                            :counterBackgroundColor="counterTextColor"
                        />
                    </div>
                </div>

                <div
                    id="contador-coupon"
                    v-if="!isMobile && popup.discount && !popup.has_countdown"
                >
                    <a
                        class="cursor-pointer"
                        style="
                            position: absolute;
                            color: #797978;
                            top: 0.3rem;
                            right: 0.5rem;
                            font-weight: 700;
                        "
                        @click="closeCountdown()"
                        ><i class="icon-cr icon-close" style="width: 1rem"></i
                    ></a>
                    <div :id="this.viewButton ? 'coupon-content-wrapper' : ''">
                        <div
                            :style="{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }"
                        >
                            <span
                                id="contador-coupon-text"
                                :style="{
                                    color: counterTextColor,
                                }"
                                >Use o cupom:</span
                            >
                        </div>
                        <div
                            id="coupon-wrapper"
                            :style="{
                                'border-color': counterBackgroundColor,
                            }"
                        >
                            <span
                                id="coupon-wrapper-text"
                                :style="{
                                    color: counterTextColor,
                                }"
                                :class="{
                                    'coupon-smaller': popup.discount.name.length > 10,
                                }"
                                >{{ popup.discount.name }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--            ADICIONAR VALIDAÇÕES DE EXIBIÇÃO CORRETAMENTE (CASO EXISTA POPUP CADASTRADO, CASO EXISTA IMAGEM..)-->
        <div v-else-if="isMobile && !isPopupHidden">
            <img
                v-if="popup.image_mobile"
                :src="`https://chico-rei.imgix.net/${popup.image_mobile}?auto=compress,format&q=65&fit=crop`"
                alt="Banner Pop Up Mobile"
                style="position: fixed; bottom: 121px; left: 0; width: 100vw; z-index: 1000"
            />
            <div
                id="contador"
                :style="{
                    'background-color': popup.background_color,
                }"
                :class="[
                    popup ? 'countdown-popup' : '',
                    popup && popup.has_countdown ? 'not_countdown' : '',
                    popup.image_mobile ? 'count-popup-img' : '',
                ]"
            >
                <a
                    class="cursor-pointer"
                    style="
                        position: absolute;
                        color: black;
                        top: 0.8rem;
                        right: 1rem;
                        font-weight: 700;
                    "
                    @click="closeCountdown()"
                    ><i
                        class="icon-cr icon-close"
                        :style="{ color: popup.color }"
                        style="width: 1.3rem"
                    ></i
                ></a>
                <div class="contador-content-mobile">
                    <div id="contador-coupon" v-if="isMobile || popup">
                        <span id="contador-coupon-text" :style="{ color: popup.color }">{{
                            this.popup.popup_phrase
                        }}</span>
                        <div id="coupon-wrapper" v-if="popup.discount">
                            <p
                                id="contador-coupon-text"
                                :style="{ color: popup.color, marginLeft: '-10px' }"
                                v-if="popup.discount && !popup.has_countdown"
                            >
                                Utilize o cupom:
                            </p>
                            <span
                                id="coupon-wrapper-text"
                                :class="{ 'coupon-smaller': popup.discount.name.length > 10 }"
                                >{{ this.popup.discount.name }}</span
                            >
                        </div>

                        <span
                            v-if="popup.has_countdown"
                            id="contador-coupon-text"
                            :style="{ color: this.popup.color }"
                            >Garanta seu desconto em até:</span
                        >

                        <Counter
                            v-if="popup.has_countdown"
                            :deadline="popup.date_to"
                            :counterTextColor="counterTextColor"
                            :counterBackgroundColor="counterBackgroundColor"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Modal from '../common/container/Modal.vue';
    import { readCookie, resizeOrientationAware, setCookie } from '../../../common/common';
    import Counter from '../contadores/Counter.vue';

    export default {
        name: 'CountdownFixed',
        components: { Counter, Modal },
        props: {
            showDays: Boolean,
            viewButton: true,
            hidePopup: false,
            popup: null,
        },
        data() {
            return {
                counterTextColor: '#ff8000',
                counterBackgroundColor: '#000000',
                isMobile: false,
                resizeAwareRemoveCb: null,
                isPopupHidden: false,
            };
        },

        mounted() {
            this.resizeAwareRemoveCb = resizeOrientationAware(() => {
                this.isMobile = window.innerWidth < 768;
            }, true);
        },

        beforeDestroy() {
            this.resizeAwareRemoveCb();
        },

        methods: {
            closeCountdown() {
                let d = new Date();
                d.setTime(d.getTime() + 1 * 4 * 60 * 60 * 1000);

                setCookie('counter_popup', '1', d.toUTCString(), '/'); // Esconde por 5 horas
                setCookie('not_count_shipping', '1', d.toUTCString(), '/'); // Esconde por 5 horas
                setCookie('not_count_daybreak', '1', d.toUTCString(), '/'); // Esconde por 5 horas

                let hiddenPopups = readCookie('hidden_popups');
                if (hiddenPopups) {
                    hiddenPopups += '|' + this.popup.id;
                } else {
                    hiddenPopups = this.popup.id;
                }
                d.setTime(d.getTime() + 1 * 4 * 60 * 60 * 1000);
                setCookie('hidden_popups', '' + hiddenPopups, d.toUTCString(), '/');
                setCookie('adfsacasd', '' + hiddenPopups, d.toUTCString(), '/');

                this.isPopupHidden = true;
            },
        },

        computed: {
            contadorContentStyle() {
                return !this.popup.has_countdown
                    ? {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                      }
                    : {};
            },
        },
        watch: {
            hidePopup(newVal) {
                this.isPopupHidden = newVal;
            },
        },
    };
</script>
