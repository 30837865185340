<template>
    <Popup :popup="activePopup" :view-button="false" class="d-block" v-if="activePopup" />
</template>
<script>
    import Popup from './Popup.vue';
    import { popupService } from '../../../common/service/resource';
    import { readCookie } from '../../../common/common';

    export default {
        components: {
            Popup,
        },
        data() {
            return {
                popups: [],
                activePopup: null,
                currentRoute: window.location.pathname,
            };
        },
        watch: {
            popups: {
                handler(newPopups) {
                    if (newPopups.length > 0) {
                        this.checkActivePopup();
                    }
                },
                deep: true,
            },
        },
        methods: {
            fetchPopups() {
                popupService
                    .query({ include: ['discount', 'urls'] })
                    .then((response) => {
                        this.popups = response.data.data;
                    })
                    .catch((error) => {
                        console.error('Error fetching popups:', error);
                    });
            },
            checkActivePopup() {
                const now = new Date();
                const hiddenPopups = readCookie('hidden_popups')
                    ? readCookie('hidden_popups').split('|')
                    : [];
                const utmSourceCookie = (readCookie('utm_source') || '').toLowerCase();
                const utmCampaignCookie = (readCookie('utm_campaign') || '').toLowerCase();

                for (let popup of this.popups) {
                    if (hiddenPopups.includes(popup.id.toString())) {
                        continue;
                    }

                    if (popup.only_logged_customer && !this.$scope.CUSTOMER) {
                        continue;
                    }

                    // // Verifica se o popup é para lojistas e se o usuário é lojista
                    const isWholesaler = this.$scope.IS_WHOLESALER || this.$scope.IS_SALES_REP;
                    if (popup.id_group === 3 && !isWholesaler) {
                        continue;
                    }

                    const dateFrom = new Date(popup.date_from);
                    const dateTo = new Date(popup.date_to);

                    if (!(now >= dateFrom && now <= dateTo)) {
                        continue;
                    }

                    const sourceMatches = popup.utm_source.toLowerCase() === utmSourceCookie;
                    const campaignMatches = popup.utm_campaign.toLowerCase() === utmCampaignCookie;

                    if (
                        (popup.utm_source || popup.utm_campaign) &&
                        !(
                            (sourceMatches && !popup.utm_campaign) ||
                            (sourceMatches && campaignMatches) ||
                            (!popup.utm_source && campaignMatches)
                        )
                    ) {
                        continue;
                    }

                    if (popup.urls.length === 0) {
                        this.activePopup = {
                            ...popup,
                            date_from: dateFrom,
                            date_to: dateTo,
                        };
                        return;
                    }

                    for (let urlObj of popup.urls) {
                        if (urlObj.de === this.currentRoute) {
                            this.activePopup = {
                                ...popup,
                                date_from: dateFrom,
                                date_to: dateTo,
                            };
                            return;
                        }
                    }
                }
            },
        },
        created() {
            this.fetchPopups();
        },
    };
</script>
