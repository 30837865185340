var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isPopupHidden && !_vm.isMobile)?_c('div',{class:[
            _vm.popup ? 'countdown-popup' : '',
            _vm.popup && !_vm.popup.has_countdown ? 'not_countdown' : '',
            _vm.popup.image_desktop ? 'count-popup-img' : '',
        ],style:({
            'background-color': _vm.popup.has_countdown ? _vm.counterBackgroundColor : '',
            width: _vm.popup.has_countdown ? '' : '440px',
        }),attrs:{"id":"contador"}},[(_vm.popup.image_desktop)?_c('img',{staticStyle:{"position":"fixed","bottom":"140px","left":"17px","z-index":"1000"},attrs:{"src":`https://chico-rei.imgix.net/${_vm.popup.image_desktop}?auto=compress,format&q=65&fit=crop`}}):_vm._e(),_vm._v(" "),_c('div',{class:(_vm.isMobile || _vm.popup ? 'mobile' : 'desktop') + (_vm.viewButton ? '-v2' : '-v1'),style:({
                height: _vm.popup.has_countdown ? '' : '120px',
            }),attrs:{"id":"contador-content"}},[_c('div',{style:({
                    'background-color': _vm.popup.background_color,
                }),attrs:{"id":_vm.isMobile || _vm.popup ? 'contador-mobile-wrapper-primary' : ''}},[_c('div',{style:(_vm.contadorContentStyle)},[_c('span',{style:({
                            color: _vm.popup.color,
                        }),attrs:{"id":"contador-title"},domProps:{"innerHTML":_vm._s(_vm.popup.popup_phrase)}})]),_vm._v(" "),(
                        (_vm.popup && _vm.popup.has_countdown) ||
                        (!_vm.popup.has_countdown && !_vm.popup.discount)
                    )?_c('a',{staticClass:"cursor-pointer",staticStyle:{"position":"absolute","color":"#797978","top":"0.25rem","right":"0.5rem","font-weight":"700"},on:{"click":function($event){return _vm.closeCountdown()}}},[_vm._v("X")]):_vm._e(),_vm._v(" "),((_vm.isMobile || _vm.popup) && _vm.popup.discount && _vm.popup.has_countdown)?_c('div',{attrs:{"id":"contador-coupon"}},[_c('span',{style:({ color: _vm.popup.color }),attrs:{"id":"contador-coupon-text"}},[_vm._v("Use o cupom:")]),_vm._v(" "),_c('div',{attrs:{"id":"coupon-wrapper"}},[_c('span',{class:{ 'coupon-smaller': _vm.popup.discount.name.length > 10 },attrs:{"id":"coupon-wrapper-text"}},[_vm._v(_vm._s(this.popup.discount.name))])])]):_vm._e()]),_vm._v(" "),(_vm.popup.has_countdown)?_c('div',{class:[_vm.popup.image_desktop ? 'count-popup-full' : ''],style:({
                    'background-color': _vm.counterBackgroundColor,
                }),attrs:{"id":_vm.isMobile || _vm.popup ? 'contador-mobile-wrapper-secondary' : ''}},[(_vm.popup.has_countdown)?_c('a',{staticClass:"cursor-pointer",staticStyle:{"position":"absolute","color":"#797978","top":"0.25rem","right":"0.5rem","font-weight":"700"},on:{"click":function($event){return _vm.closeCountdown()}}},[_vm._v("X")]):_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"contador-timer"}},[(_vm.popup.has_countdown)?_c('span',{style:({
                            color: '#fff',
                        }),attrs:{"id":"contador-timer-text"}},[_vm._v("Acaba em:")]):_vm._e(),_vm._v(" "),(_vm.popup.has_countdown)?_c('Counter',{attrs:{"deadline":_vm.popup.date_to,"counterTextColor":'#fff',"counterBackgroundColor":_vm.counterTextColor}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),(!_vm.isMobile && _vm.popup.discount && !_vm.popup.has_countdown)?_c('div',{attrs:{"id":"contador-coupon"}},[_c('a',{staticClass:"cursor-pointer",staticStyle:{"position":"absolute","color":"#797978","top":"0.3rem","right":"0.5rem","font-weight":"700"},on:{"click":function($event){return _vm.closeCountdown()}}},[_c('i',{staticClass:"icon-cr icon-close",staticStyle:{"width":"1rem"}})]),_vm._v(" "),_c('div',{attrs:{"id":this.viewButton ? 'coupon-content-wrapper' : ''}},[_c('div',{style:({
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        })},[_c('span',{style:({
                                color: _vm.counterTextColor,
                            }),attrs:{"id":"contador-coupon-text"}},[_vm._v("Use o cupom:")])]),_vm._v(" "),_c('div',{style:({
                            'border-color': _vm.counterBackgroundColor,
                        }),attrs:{"id":"coupon-wrapper"}},[_c('span',{class:{
                                'coupon-smaller': _vm.popup.discount.name.length > 10,
                            },style:({
                                color: _vm.counterTextColor,
                            }),attrs:{"id":"coupon-wrapper-text"}},[_vm._v(_vm._s(_vm.popup.discount.name))])])])]):_vm._e()])]):(_vm.isMobile && !_vm.isPopupHidden)?_c('div',[(_vm.popup.image_mobile)?_c('img',{staticStyle:{"position":"fixed","bottom":"121px","left":"0","width":"100vw","z-index":"1000"},attrs:{"src":`https://chico-rei.imgix.net/${_vm.popup.image_mobile}?auto=compress,format&q=65&fit=crop`,"alt":"Banner Pop Up Mobile"}}):_vm._e(),_vm._v(" "),_c('div',{class:[
                _vm.popup ? 'countdown-popup' : '',
                _vm.popup && _vm.popup.has_countdown ? 'not_countdown' : '',
                _vm.popup.image_mobile ? 'count-popup-img' : '',
            ],style:({
                'background-color': _vm.popup.background_color,
            }),attrs:{"id":"contador"}},[_c('a',{staticClass:"cursor-pointer",staticStyle:{"position":"absolute","color":"black","top":"0.8rem","right":"1rem","font-weight":"700"},on:{"click":function($event){return _vm.closeCountdown()}}},[_c('i',{staticClass:"icon-cr icon-close",staticStyle:{"width":"1.3rem"},style:({ color: _vm.popup.color })})]),_vm._v(" "),_c('div',{staticClass:"contador-content-mobile"},[(_vm.isMobile || _vm.popup)?_c('div',{attrs:{"id":"contador-coupon"}},[_c('span',{style:({ color: _vm.popup.color }),attrs:{"id":"contador-coupon-text"}},[_vm._v(_vm._s(this.popup.popup_phrase))]),_vm._v(" "),(_vm.popup.discount)?_c('div',{attrs:{"id":"coupon-wrapper"}},[(_vm.popup.discount && !_vm.popup.has_countdown)?_c('p',{style:({ color: _vm.popup.color, marginLeft: '-10px' }),attrs:{"id":"contador-coupon-text"}},[_vm._v("\n                            Utilize o cupom:\n                        ")]):_vm._e(),_vm._v(" "),_c('span',{class:{ 'coupon-smaller': _vm.popup.discount.name.length > 10 },attrs:{"id":"coupon-wrapper-text"}},[_vm._v(_vm._s(this.popup.discount.name))])]):_vm._e(),_vm._v(" "),(_vm.popup.has_countdown)?_c('span',{style:({ color: this.popup.color }),attrs:{"id":"contador-coupon-text"}},[_vm._v("Garanta seu desconto em até:")]):_vm._e(),_vm._v(" "),(_vm.popup.has_countdown)?_c('Counter',{attrs:{"deadline":_vm.popup.date_to,"counterTextColor":_vm.counterTextColor,"counterBackgroundColor":_vm.counterBackgroundColor}}):_vm._e()],1):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }